// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeTest,
    placeUndo,
    placeRedo,
    placeBoldText,
    placeBoldLeftText,
    placeErase,
    plotFunction,
    createAxes,
    createSpace,
    drawPoint,
    writeMediumText,
    writeSmallText,
    writeLargeText,
    drawSegment
} from '../../../../common/edliy.js';
const Boxes = {
  box1: function () {
///////////////////////////////////GLOBAL SETTINGS//////////////////////
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
//////////////////////////////////LOCAL SETTINGS//////////////////////////////////
var graph = createSpace(-2, 11, -2, 11);
graph.options.layer['image'] =12;
graph.options.layer['circle'] =13;
graph.options.layer['chart'] =14;
graph.options.layer['sector'] =15;
graph.options.layer['line'] =15;
/////////////////////////////////////////////////////////////////////
makeResponsive(graph);
///////////////////////////////////////////////////////////
createAxes(graph);
placeTitle(graph, 'Visualizing Mappings','(Draw X-Y Points on the Graph)');
placeLogo(graph);
var i=1; var j=0; var x ='x'; var y ='y';
var shuffle = placeShuffle(graph);
var rght = placeImage(graph, '/assets/check.svg', 7.5, -1.5);
var wrng = placeImage(graph, '/assets/cross.svg', 7.5, -1.5);
rght.setAttribute({visible:false});
wrng.setAttribute({visible:false});
shuffle.setLabel('Tap to Shuffle');
shuffle.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
shuffle.on('over', function () {this.label.setAttribute({visible:true});});
shuffle.on('out', function () {this.label.setAttribute({visible:false});});
///////////////////////////////////////////////////
var test =placeTest(graph);
test.setLabel('Tap to Check Your Answer');
test.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
test.on('over', function () {this.label.setAttribute({visible:true});});
test.on('out', function () {this.label.setAttribute({visible:false});});
////////////////////////////////////////////////////
shuffle.on('down', function(){
  rght.setAttribute({visible:false});
  wrng.setAttribute({visible:false});
  graph.removeObject([Points,v, h]);
  k =0;
  if(i<3){i+=1;}else{i=1}
});
//////////////////////////////////////////////
var k=0;
var Points=[];
var v=[];
var h=[];
var xList=[0,0,0,0];
var yList=[1,1,1,1];
//////////////////////////////////////////////////
graph.on('up',
          function (evt)
          {
          if(k<4 && !shuffle.mouseover && !test.mouseover)
          {
          Points[k] = graph.create('point', graph.getUsrCoordsOfMouse(evt), {name:'',size:4, snapToGrid:true});
          Points[k].setAttribute({fixed:true, color:'blue'});
          v[k] =drawSegment(graph, [Points[k].X(), Points[k].Y()], [Points[k].X(), 0.0]);
          v[k].setAttribute({dash:1});
          h[k] = drawSegment(graph, [Points[k].X(), Points[k].Y()], [0.0, Points[k].Y()]);
          h[k].setAttribute({dash:1});
          xList[k] = Points[k].X();
          yList[k] = Points[k].Y();
          k+=1;
          }
          graph.update();
          });
/////////////////////////////////////////////////////////
var array =[];
var domain=[1, 3, 5, 7];
var linRange = [2, 4, 6, 8];
var quadRange=[0, 1, 3, 5];
var absRange=[6, 5, 4, 3];
array.push(domain);
array.push(linRange);
array.push(quadRange);
array.push(absRange);
/////////////////////////////Cubic FUNCTION//////////////
test.on('down', function(){
  rght.setAttribute({visible:false});
  wrng.setAttribute({visible:false});
  if(JSON.stringify(xList)==JSON.stringify(domain) && JSON.stringify(yList)==JSON.stringify(array[i])){rght.setAttribute({visible:true})}else{wrng.setAttribute({visible:true})}});

//writeMediumText(graph, 2+0.5, 7+0.5, ()=>xList);
////////////////////////////////////////////////
var xText =writeMediumText(graph, -1.5, 8+0.5-k, 'x');
xText.setAttribute({color:'blue'});
var yText =writeMediumText(graph, -1+0.5, 8+0.5-k,'y' );
yText.setAttribute({color:'blue'});
for(let k=0; k<4; k++){
var aText =writeMediumText(graph, -1.5, 7+0.5-k, ()=>array[0][k]);
aText.setAttribute({color:'blue'});
var bText= writeMediumText(graph, -1+0.5, 7+0.5-k, ()=>array[i][k]);
bText.setAttribute({color:'blue'});
}
/////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////END OF OPERATIONS///////////////////////////////
//brd1.unsuspendUpdate();
},
}
export default Boxes;

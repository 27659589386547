<template>
  <div>
    <h3 ref="intro">
      What is a Function?
    </h3>

    <p class="big mt-3">
      A function (also called a mapping) is a mathematical rule that relates every element in one set to
      exactly one elment in another set. <br>
      The first set of numbers is called the domain of the function while the other set of numbers is called the range of the function.
      <br>
      For example, the relation described by  \( y = 2x+3\) is a function because for every value of \(x\), there is exactly one value of \(y\).
    </p>
    <h3 ref="types">
      Notation
    </h3>
    <p class="big mt-3">
      The mathematical notation for a function is  \(y = f(x)\), and is read as &mdash; \(y\)  is equal to \(f\) of
      \(x\).<br> The letter \(f\) stands for the function, \(x\) stands for the input variable and \(y\) stands for the output variable.
    </p>
    <!--
    <h3 ref="types">
      Finding Probability of an Event
    </h3>
    <br>
    <p>
      Let's consider a random experiment (say rolling of a die) that can give multiple outcomes (for example &mdash; the rolling of a die can give 1, 2, 3, 4, 5, or 6).
<br><br>
      Let's say we have to calculate the probability of occurrence of a given outcome (say getting a 2). Below are the steps to find this probability.
    </p>
    <ul class="a mx-3">
      <li> <h5> Step 1: Total Number of Possible Outcomes (<i>N</i> )</h5> </li>
      <p>
        Start with finding the number of all possible outcomes that can come
         out of the random experiment.  Let's call this number <i>N</i>.
      </p>
      <li> <h5> Step 2: Number of Favorable Outcomes (<i>n</i> ) </h5> </li>
      <p>
        Next, we calculate the number of ways in which the desired outcome can be obtained.
         Let's call this number <i>M</i>.
      </p>
      <li> <h5> Step 3: Calculate the Ratio <i>M</i> over <i>N</i></h5> </li>
      <p>
        Calculate the ratio of \(M\) over \(N\). This ratio gives the probability of event A.
        $$\text{Probability of Event A=}P(A) = \frac{M}{N}$$
      </p>
    </ul> -->
    <h3 ref="pg">
      MagicGraph | Find the Function
    </h3>
    <br>
    <h5>Getting Started</h5>
    <p class="big mt-3">
      In this MagicGraph, you are given two sets of numbers: x and y. Your goal is
      to select the function from the graph that represents the mapping from x to y.
      <br>
      To select a function, tap on it. Once selected, then tap on the test button to check your answer.
      <br>
    </p>
    <h5> Icons on the MagicGraph </h5>
    <p>
      You can tap on <i class="shuffler ma-1" /> icon  to shuffle numbers.
      You can tap on the <i class="tester ma-1" /> icon to check your answer.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'FunctionsAndRelations',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Functions & Relations ';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Probability', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Probability of an Event', img:'/assets/number-2.svg', action: () => this.goto('types')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Functions',
          //titleTemplate: '%s | Learn interactively',
          meta: [
                  {vmid:'description', name: 'description', content: 'Learn interactively about functions and mappings'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>
